import siteConfig from "funk-experts.config.json";
import merge from "lodash/merge";
import uniqBy from "lodash/uniqBy";
import { GetStaticProps, NextPage } from "next";
import React from "react";
import { SiteLocale } from "src/__generated__/client.codegen";
import { SharedContext } from "src/components/SharedContext";
import { defaultBrand } from "src/lib/ssr/brands";
import { GetStaticPropsMiscService } from "src/lib/ssr/getStaticProps/getStaticProps.misc.service";
import { SiteSettings } from "src/lib/ssr/utils";
import { ErrorPage } from "src/templates/ErrorPage";

type Error404PageProps = {
	defaultSiteSettings: Omit<SiteSettings, "translations">;
	allSiteSettings: Array<Omit<SiteSettings, "translations">>;
	translations: Array<{
		locale: SiteLocale;
		translations: Record<string, string> | undefined;
	}>;
};

const Error404Page: NextPage<Error404PageProps> = ({
	defaultSiteSettings,
	allSiteSettings,
	translations,
}) => {
	const [siteSettings, setSiteSettings] = React.useState<SiteSettings>({
		...defaultSiteSettings,
		translations: translations.find(
			({ locale }) => locale === defaultSiteSettings.locale,
		)?.translations,
	});

	React.useEffect(() => {
		const previewBrand = new URL(window.location.href).searchParams.get(
			"previewBrand",
		);

		if (previewBrand) {
			const siteSettings = allSiteSettings.find(
				({ brandConfig: { slug } }) => previewBrand === slug,
			);

			if (siteSettings) {
				setSiteSettings(siteSettings);

				return;
			}
		}

		const url = window.location.href;

		const siteSettings = allSiteSettings.find(({ brandConfig: { slug } }) =>
			url.includes(slug),
		);

		if (siteSettings) {
			setSiteSettings({
				...siteSettings,
				translations: translations.find(
					({ locale }) => locale === siteSettings.locale,
				)?.translations,
			});
		}
	}, [allSiteSettings, translations]);

	return (
		<SharedContext siteSettings={siteSettings}>
			<ErrorPage siteSettings={siteSettings} />
		</SharedContext>
	);
};

export const getStaticProps: GetStaticProps<
	Error404PageProps,
	{ brandSlug: string }
> = async (ctx) => {
	const merged = merge(ctx, { params: { brandSlug: defaultBrand.slug } });

	const propsService = new GetStaticPropsMiscService(merged);

	await propsService.init();

	const {
		props: { siteSettings: defaultSiteSettings },
	} = await propsService.get();

	const allBrandSettings = siteConfig.brands
		// we don't need to get the same brand twice
		.filter(({ slug }) => slug !== defaultBrand.slug)
		.map(async ({ slug }) => {
			const merged = merge(ctx, { params: { brandSlug: slug } });
			const propsService = new GetStaticPropsMiscService(merged);

			await propsService.init();

			return propsService.get();
		});

	const serviceResults = await Promise.all(allBrandSettings);

	const allSiteSettings = [
		defaultSiteSettings,
		...serviceResults.map(({ props: { siteSettings } }) => siteSettings),
	];

	const { translations, ...rest } = defaultSiteSettings;

	return {
		revalidate: 60,
		props: {
			defaultSiteSettings: rest,
			allSiteSettings: allSiteSettings.map(
				({ translations, ...rest }) => rest,
			),
			// we send the translations separately to avoid sending them multiple times & blowing up the size of getStaticProps
			translations: uniqBy(
				allSiteSettings.map(({ locale, translations }) => ({
					locale,
					translations,
				})),
				"locale",
			),
		},
	};
};

export default Error404Page;

// 🔬 e2e
