import { chakra, Flex, Link, useMultiStyleConfig } from "@chakra-ui/react";
import Head from "next/head";
import React from "react";
import { renderMetaTags } from "react-datocms";
import { Footer } from "src/components/Footer/Footer";
import { Header } from "src/components/Header/Header";
import { Hx } from "src/components/Heading/Heading";
import { Layout } from "src/components/Layout/Layout";
import { useTranslatedString } from "src/i18n/i18n";
import { Error404 } from "src/icons";
import type { SiteSettings } from "src/lib/ssr/utils";

export const ErrorPage: React.FC<{
	siteSettings: SiteSettings;
}> = ({ siteSettings }) => {
	const styles = useMultiStyleConfig("ErrorPage", {});
	const t = useTranslatedString();

	const metaTags = [
		{
			__typename: "Tag",
			tag: "meta",
			content: null,
			attributes: {
				name: "robots",
				content: "noindex",
			},
		},
	];

	const logoData = siteSettings.headerLogo ?? undefined;
	const site = siteSettings.footerSettings.title ?? "Funk";

	return (
		<Layout
			highlightColor={null}
			variant="errorPage"
			header={
				<Header logoData={logoData}>
					{/* TODO: 🚧 FAQ page link and contact button when available */}
					<chakra.div />
				</Header>
			}
			footer={
				<Footer
					variant="minimal"
					footerSettings={siteSettings.footerSettings}
				/>
			}
		>
			<Head>{renderMetaTags(metaTags)}</Head>
			<Flex sx={styles.container}>
				<chakra.div sx={styles.centerWrapper}>
					<Error404 sx={styles.icon} />
					<Hx sx={styles.headline}>{t("errorPage.title.oops")}</Hx>
					<chakra.div sx={styles.description}>
						{/* differing max widths in place to match design. */}
						<chakra.p maxW={{ base: "80%", md: "unset" }}>
							{t("errorPage.description.notFound")}
						</chakra.p>
						<chakra.p maxW={{ base: "85%", md: "unset" }}>
							{t("errorPage.description.findHere")}
						</chakra.p>
					</chakra.div>
					<Link href="/" sx={styles.link} id="click_error_home">
						{t("errorPage.link.homepage", { site })}
					</Link>
				</chakra.div>
			</Flex>
		</Layout>
	);
};

// 🔬 TBD: Please evaluate
